.resolve-iframe {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000000;
  border: 0;
}

.hide-iframe {
  visibility: hidden;
  opacity: 0;
}

.checkout-completion {
  font-family: var(--font-family);
  max-height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 20px;
  overscroll-behavior: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@keyframes fadein {
  to {
    opacity: 1;
  }
}

#orderSet {
  opacity: 0;
  width: 100%;
  max-width: 500px;
  animation: fadein 1s linear 0.7s forwards;

  .products {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    min-height: 135px;
    padding: 12px;
    width: 100%;
    max-width: 600px;
    position: relative;
    justify-content: space-evenly;
    align-items: center;
    z-index: 5;

    &--background {
      border-radius: 20px;
      background-color: rgba($color: #000000, $alpha: 0.4);
    }

    .product {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 16px;
      }

      img {
        height: 55px;
        margin-bottom: 16px;
      }
    }
  }
}

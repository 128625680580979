.popup-handler {
  &__fail-screen {
    font-family: var(--font-family);

    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    z-index: 2000;
    color: white;
    gap: 1em;

    &__action-button {
      font-size: 18px;
      text-shadow: 0 1px 1px black;
      box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.3);
    }
  }
}

.slick-slider.slick-initialized {
  max-width: 416px;
  width: 100%;

  @media (max-width: 416px) {
    width: 95%;
  }

  &.pipa {
    max-width: 528px;
    width: calc(100% - 32px);

    .slick-list {
      padding-top: 6px;
    }
  }

  &.ghost {
    width: calc(100% - 32px);
  }

  .slick-list {
    padding: 20px 0 25px 0;
    overflow-x: hidden;
    overflow-y: auto;
    &:only-child {
      padding-bottom: 13px;
    }
  }

  .slick-dots {
    bottom: 9px;

    button::before {
      color: white;
    }

    li {
      margin: 0;
      height: 15px;
      width: 15px;

      button::before {
        font-size: 8px;
      }
    }
  }

  .slick-arrow {
    z-index: 1;
    top: 42%;
    background: white;
    border-radius: 50%;
    opacity: 0.75;

    &::before {
      font-size: 24px;
      position: absolute;
      top: 0;
      left: -2px;
      opacity: 1;
      color: black;
    }

    &.slick-next {
      right: -5px;
    }
    &.slick-prev {
      left: -5px;
    }
  }

  .slick-slide > div > div {
    display: flex !important;
    justify-content: center !important;
  }

  .carousel-item {
    cursor: pointer;
    transition: 0.5s;

    @media (max-width: 762px) {
      // padding: 0 10px;
    }
  }
}

.carousel-divider {
  height: 3px;
  width: 60%;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-top: 4px;
  margin-bottom: 4px;

  &-me2on {
    width: 200px;
    background: linear-gradient(90deg, #75257a 0%, #fdd8fd 46.56%, #7a2783 100%);
    filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.25));
  }
}

@media (min-width: 375px) {
  .slick-slider.slick-initialized {
    .slick-arrow {
      height: 24px;
      width: 24px;

      &::before {
        font-size: 30px;
        left: -3px;
        top: -1px;
      }
    }
  }
}

@media (min-width: 848px) {
  .slick-slider.slick-initialized {
    max-width: 832px;

    &.pipa {
      max-width: 558px;
    }
  }
}

@media (min-width: 1024px) {
  .carousel-item:hover {
    transform: scale(1.05);
  }
}

@media (min-width: 1264px) {
  .slick-slider.slick-initialized {
    max-width: 1248px;

    .slick-track {
      display: flex;
      justify-content: center;

      .slick-slide {
        max-width: 416px;
      }
    }

    &.pipa {
      max-width: 558px;

      .slick-track {
        .slick-slide {
          max-width: 558px;
        }
      }
    }
  }
}

.slick-slider.locobingo.slick-initialized {
  min-width: 288px;
  width: 100%;

  .slick-track {
    display: flex;
    justify-content: center;

    .slick-slide {
      min-width: 288px;
    }
  }
}

@media (min-width: 768px) {
  .slick-slider.locobingo.slick-initialized {
    max-width: 618px;

    .slick-track {
      display: flex;
      justify-content: center;

      .slick-slide {
        max-width: 297px;
        margin: 0 6px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .slick-slider.locobingo.slick-initialized {
    max-width: 824px;

    .slick-track {
      display: flex;
      justify-content: center;

      .slick-slide {
        max-width: 400px;
      }
    }
  }
}

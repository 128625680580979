@import '../../variables.scss';

.shop-footer {
  width: 100%;
  font-family: sans-serif;
  color: white;
  display: flex;
  justify-content: flex-end;
  padding: 1vh 3vh;
  max-width: 800px;
  z-index: 6;

  .footerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: 100%;

    &.pipa {
      max-width: 528px;
      margin: 0 auto;
    }

    &.play-simple {
      max-width: 400px;
      margin: auto;
    }

    div {
      a {
        color: inherit;
      }
    }
  }

  h6 {
    cursor: pointer;
  }

  .logo {
    height: 20px;
  }

  .publisherLogo {
    width: auto;
    height: 28px;
  }

  .quadraticDot {
    width: 1px;
    height: 1px;
    background-color: currentColor;
    display: inline-block;
  }

  .footer-divider {
    height: 1px;
    width: 100%;
    background-color: white;
    position: absolute;
    bottom: 0;
  }

  .pm-footer-logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;

    img {
      max-height: 30px;
    }
  }

  .footer-version {
    font-size: 10px;
    width: 100%;
    align-items: center;
    opacity: 0.5;
  }

  .footer-section {
    flex: 1;
    white-space: nowrap;
  }

  @media only screen and (min-width: 768px) {
    .footer-version {
      width: 100%;
      align-items: flex-start;
    }
  }
}

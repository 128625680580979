body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
  margin: 0;
  box-sizing: border-box;
  background-color: black;
}

@media (orientation: landscape) {
  body {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

@supports (-webkit-touch-callout: none) {
  @media (orientation: landscape) {
    body {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
